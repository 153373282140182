 <!--------------------------------------------
| TABELA DE LISTAGEM DE USUÁRIOS POR UNIDADE 
|-------------------------------------------->

<template>
  <div>
    <v-card class="rounded-lg" elevation="1">
      <section class="header-table">
          <v-text-field v-model="search" class="rounded-lg" append-icon="mdi-magnify" label="Pesquisar..." single-line
            hide-details outlined dense></v-text-field>
          <div class="space"></div>
          <v-btn class="rounded-lg" elevation="0" color="primary" @click="openCreateUser">
            <v-icon small class="mr-2" color="#FFFFFF"> mdi-account-plus </v-icon>
            <span class="textBtn"> Adicionar Usuário </span>
          </v-btn>
          <div class="spacediferent"></div>
          <v-btn class="rounded-lg" elevation="0" color="success" @click="voltar">
            <v-icon small class="mr-2" color="#FFFFFF"> mdi-keyboard-backspace </v-icon>
            <span class="textBtn"> Voltar </span>
          </v-btn>
      </section>

      <v-card elevation="0">
        <v-card-title class="unity"
          @click="showUnity()">
          {{$store.state.titulo}}
        </v-card-title>
      </v-card>

      <div class="space"></div>

      <v-data-table 
        :headers="headers" 
        :items="usuarios" 
        :search="search" 
        :footer-props="{ 'items-per-page-text': 'Usuários por Página',}" 
        :page="page" :pageCount="numberOfPages" :options.sync="options" :sort-by.sync="sortBy"
        :server-items-length="totalUsers" :loading="loading">

          <template v-slot:[`item.actions`]="{ item }">
            <Edit :resource="item" v-on:eventname="crudUser" />
          </template>

          <template v-slot:[`item.cpf`]="{ item }">
            {{ item.cpf | VMask("###.###.###-##") }}
          </template>
      </v-data-table>

<!-----------------------------------------
|MODAL DE CRIAÇÃO DE USUÁRIO
|------------------------------------------>

      <v-dialog v-model="dialog" width="600px" persistent>
        <v-card class="create-user-dialog rounded-lg">
          <div class="banner">
            <h4>Criar Usuário</h4>
          </div>

          <AlertError class="mb-5" v-model="displayError" :resource="displayError" />

          <v-form @submit.prevent="create" ref="form">
            <v-text-field 
            class="rounded-lg" 
            label="Nome" 
            placeholder="Nome completo" 
            outlined v-model="name"
            :rules="nameRules" 
            dense></v-text-field>
            
            <v-text-field 
            class="rounded-lg" 
            label="CPF" 
            placeholder="CPF" 
            outlined 
            v-model="cpf" 
            maxlength="14"
            :rules="cpfRules" 
            v-mask="'###.###.###-##'" 
            dense></v-text-field>

            <v-select 
            :rules="sexRules" 
            :items="sex" 
            v-model="sex_id" 
            item-text="name" 
            item-value="id" 
            dense outlined
            label="Gênero">
            </v-select>

            <v-text-field 
            class="rounded-lg" 
            label="E-mail" 
            placeholder="E-mail" 
            outlined v-model="email"
            :rules="emailRules" 
            dense></v-text-field>

            <v-text-field 
            class="rounded-lg" 
            label="Telefone" 
            placeholder="Telefone" 
            outlined 
            v-model="telephone"
            :rules="telephoneRules" 
            v-mask="'(##)#####-####'"
            maxlength="15" 
            dense></v-text-field>

            <v-text-field 
            class="rounded-lg" 
            label="Matrícula" 
            placeholder="Matrícula" 
            outlined 
            v-model="registration"
            :rules="registrationRules" 
            maxlength="11" 
            dense></v-text-field>

            <v-select 
            :rules="perfilRules" 
            :items="perfil" 
            v-model="profile_id" 
            item-text="name" 
            item-value="id" 
            dense
            outlined 
            label="Tipo de Perfil">
            </v-select>

            <v-card-actions class="cardFooter">
                <v-spacer></v-spacer>
                  <v-btn class="rounded-lg" color="#e74c3c" text elevation="0" @click="dialog = false">
                    <span>Cancelar</span>
                  </v-btn>

                  <v-btn class="rounded-lg" color="blue" text elevation="0" @click="limpar">
                    <span>Limpar</span>
                  </v-btn>

                  <v-btn class="rounded-lg" elevation="0" color="#27ae60" type="submit">
                    <span class="textBtn">Salvar Usuário</span>
                    <v-icon color="#FFFFFF" small class="ml-2">mdi-arrow-right</v-icon>
                  </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
    </v-snackbar>

  </div>
</template>
  
<script>
import axios from "@/axios";
import Edit from "./EditUsuario-comp.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
        dialog: false,
        search: "",
        headers: [
          { text: "CPF", value: "cpf" },
          { text: "Nome", value: "name", },
          { text: "Tipo de Perfil", value: "profile" },
          { text: "Ações", value: "actions", align:"center", sortable: false },
        ],
        nameRules: [(v) => !!v || "O campo é obrigatório"],
        emailRules: [
          (v) => !!v || "O campo é obrigatório",
          (v) => /.+@.+/.test(v) || "O e-mail não é válido.",
        ],
        cpfRules: [(v) => !!v || "O campo é obrigatório"],
        sexRules: [(v) => !!v || "Campo obrigatório."],
        telephoneRules: [(v) => !!v || "Campo obrigatório."],
        registrationRules: [(v) => !!v || "Campo obrigatório."],
        perfilRules: [(v) => !!v || "Campo obrigatório."],

        displayError: {
          display: false,
          message: [],
        },

        page: 1,
        totalUsers: 0,
        numberOfPages: 0,
        passengers: [],
        loading: true,
        options: {},
        sortBy: "name",
        isTecnHemoba: false,
        usuarios: [],
        message: "",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
        unity_id: localStorage.getItem('item-unidade'), //recebe o valor do id da unidade 
        sex: "",
        perfil: "",
        name: "",
        cpf: "",
        sex_id: "",
        email: "",
        telephone: "",
        registration: "",
        profile_id: "",
      };
  },

  props: {
      resource: {
        type: Object,
      },
    },

  components: {
    Edit,
    AlertError,
  },

  watch: {
    sortBy: {
      handler() {}
    },
    
    options: {
      handler() {
        this.indoDataTable();
      },
    },

    deep: true,
  },

  methods: {
    //Abre o modal de criação de usuário
    openCreateUser() {
      this.dialog = true;
      axios.get(`/profiles`).then((response) => {
        this.loading = false;
        this.perfil = response.data.data;
      });
      axios.get(`/sex`).then((response) => {
        this.loading = false;
        this.sex = response.data.data;
      });
    },

    //Carrega as coisas ao acessar a tela
    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;

      axios.get(`/users?page=${pageNumber}`).then((response) => {
        this.loading = false;        
        //alert(this.teste);
        this.usuarios = response.data.data.filter((x) => x.unity_id == this.$route.query.item.id);
        this.totalUsers = response.data.total;
        this.numberOfPages = response.data.last_page;
      });
    },

    redirectDigitalSignature(){
      if(this.isTecnHemoba == true){
        this.$router.push({ name: "Assinatura Digital" });
      }
    },

    //Botão de voltar para listagem de unidades
    voltar() {
      this.$router.push("/perfis-de-usuarios");
    },

    //Botão de limpar
    limpar() {
        this.name = '';
        this.cpf = '';
        this.sex_id = '';
        this.email = '';
        this.telephone = '';
        this.registration = '';
        this.profile_id = '';
    },

    //Criação de Usuários
    create() {
      if (this.$refs.form.validate()) {
        const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
        const dados = new Object();
        //dados.unity_id = this.$route.query.item.id;
        dados.unity_id = localStorage.getItem('item-unidade');
        dados.name = this.name;
        dados.cpf = newCPF;
        dados.sex_id = this.sex_id;
        dados.email = this.email;
        dados.telephone = this.telephone;
        dados.registration = this.registration;
        dados.profile_id = this.profile_id;
        if(dados.profile_id == 5){
          this.isTecnHemoba = true
        }
        axios
          .post(`/users`, dados)
          .then((response) => {
            this.indoDataTable();
            this.message = response.data.message;
            this.redirectDigitalSignature()
            this.snackbar = true;
            this.dialog = false;
            this.unity_id = ""
            this.name = ""
            this.cpf = ""
            this.sex_id = ""
            this.email = ""
            this.telephone = ""
            this.registration = ""
            this.profile_id = ""
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });   
      }
    },

    crudUser(resource) {
      if (resource == "") {
        this.indoDataTable();
      } else {
        axios.delete(`/users/${resource}`).then((response) => {
          this.indoDataTable();
          this.message = response.data.message;
          this.snackbar = true;
        });

      }
    },
  },

  mounted() {
    this.indoDataTable();
  },
};
</script>
  
<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}
.spacediferent {
  width: 7px;
}

.unity{
  font-size: 12px;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.v-card__title {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.0125em;
    font-family: 'Roboto';
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #0a6621,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #0f852d,
      #28dd5e);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner>h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
  