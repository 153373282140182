<!--------------------------------------------
| TELA DE EDIÇÃO
|-------------------------------------------->

<template>
  <div class="tableOptions">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="mr-2 iconeEditar" color="blue" @click="dialogEditar = true">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>
  
    <v-tooltip bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="iconeDeletar" color="red" @click="dialogAPagar = true">
            mdi-account-lock
          </v-icon>
        </span>
      </template>
      <span>Desativar</span>
    </v-tooltip>

    <!-- Modal de Edição -->
    <v-dialog
      v-model="dialogEditar"
      width="600px"
      class="modelDialog"
      persistent
    >

      <v-card class="card rounded-lg">
        <div class="banner-edit">
          <h4>Editar Usuário</h4>
        </div>

        <AlertError v-model="displayError" :resource="displayError" />

        <div class="space"></div>

        <v-form @submit.prevent="edit" v-model="valid" lazy-validation ref="form">

          <v-text-field
            label="Nome"
            placeholder="Nome"
            outlined
            v-model="form.name"
            :rules="nameRules"
            dense
          ></v-text-field>

          <v-text-field
            label="CPF"
            placeholder="CPF"
            outlined
            v-mask="'###.###.###-##'"
            v-model="form.cpf"
            :rules="cpfRules"
            dense
          ></v-text-field>

          <v-select
            :rules="sexRules" 
            :items="sex" 
            v-model="form.sex_id"
            item-text="name" 
            item-value="id"
            dense
            outlined
            label="Gênero">
            </v-select>

          <v-text-field
            label="E-mail"
            placeholder="E-mail"
            outlined
            v-model="form.email"
            :rules="emailRules"
            dense
          ></v-text-field>

          <v-text-field
              class="rounded-lg"
              label="Telefone"
              placeholder="Telefone"
              outlined
              v-model="form.telephone"
              :rules="telephoneRules"
              v-mask="'(##)#####-####'"
              maxlength="15"
              dense
          ></v-text-field>

          <v-text-field
            class="rounded-lg"
            label="Matrícula"
            placeholder="Matrícula"
            outlined
            v-model="form.registration"
            :rules="registrationRules"
            maxlength="11"
            dense
          ></v-text-field>

          <v-select 
            :rules="perfilRules"
            :items="perfil" 
            v-model="form.profile_id" 
            item-text="name" 
            item-value="id"
            dense
            outlined
            label="Tipo de Perfil">
          </v-select>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

              <v-btn class="rounded-lg btn" color="red" text elevation="0" @click="cancelEdit">
                <span>Cancelar</span>
              </v-btn>

              <v-btn class="rounded-lg" color="success" elevation="0" type="submit" @click="edit()" >
                <span class="textBtn">atualizar</span>
                <v-icon small class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAPagar" width="400px" class="modelDialog" persistent>
      <v-card class="card rounded-lg">
          <div class="banner-delete">
              <h4>Desativar Usuário</h4>
          </div>

          <div class="textInfo">
            <h6 class="textDelete">
              Deseja desativar <span class="userName">{{ form.name }}</span> dos
              usuários cadastrados?
            </h6>
            <h6 class="alertText">(Essa pode ser revertida futuramente)</h6>
          </div>

          <v-card-actions class="cardFooter">
            <v-btn class="rounded-lg btn" color="red" text elevation="0" @click="dialogAPagar = false">
              <span>Cancelar</span>
          </v-btn>

          <v-btn class="rounded-lg" color="#27ae60" elevation="0" type="submit" @click="desativar">
            <span class="textBtn">Desativar</span>
              <v-icon color="#FFFFFF" small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
  </div>
</template>

<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      valid: true,
      nameRules: [(v) => !!v || "O campo é obrigatório"],
      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+/.test(v) || "O campo e-mail não válido.",
      ],
      cpfRules: [(v) => !!v || "O campo é obrigatório"],
      sexRules: [(v) => !!v || "Campo obrigatório."],
      telephoneRules: [(v) => !!v || "Campo obrigatório."],
      registrationRules: [(v) => !!v || "Campo obrigatório."],
      perfilRules: [(v) => !!v || "Campo obrigatório."],

      loading: true,
      dialogEditar: false,
      dialogAPagar: false,
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },

      sex: [],
      unity_id: "",
      form: {},
      perfil: [],
      name: "",
      cpf: "",
      sex_id: "",
      email: "",
      telephone: "",
      registration: "",
      profile_id: ""
    };
  },

  props: {
    resource: {
      type: Object,
    }
  },

  components: {
    AlertSuccess,
    AlertError
  }, 

  created(){
    this.form = {...this.resource};
    this.form.sex_id = parseInt(this.form.sex_id)
    this.form.profile_id = parseInt(this.form.profile_id)
    this.indoDataTable()

    axios.get('/users').then((response) => {
      this.users = response.data;
    });

  },

  //Método de edição
  methods: {
    edit() {
      if (this.$refs.form.validate() == true) {
        const newCPF = this.form.cpf.replace(/[^a-zA-Z0-9]/g, "");
        // const newTelephone = this.form.telephone.replace(/[])
        const dados = new Object();
        dados.id = this.form.id;
        //dados.unity_id = this.$route.query.item.id;
        dados.unity_id = localStorage["item-unidade"];
        dados.name = this.form.name;
        dados.cpf = newCPF;
        dados.sex_id = this.form.sex_id;
        dados.email = this.form.email;
        dados.telephone = this.form.telephone;
        dados.registration = this.form.registration;
        dados.profile_id = this.form.profile_id;
  
        axios
          .put(`/users/${dados.id}`, dados)
          .then((response) => {
            this.displaySuccess.message = response.data.message;
            this.displaySuccess.display = true;
            this.dialogEditar = false;
            this.$emit("eventname", '');
          })
          .catch((err) => {

            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          })
      }
    },
    //Carrega as coisas ao acessar a teladesativar
    indoDataTable(){
      axios.get(`/profiles`).then((response) => {
      this.perfil = response.data.data;
      });

      axios.get(`/sex`).then((response) => {
        this.sex = response.data.data;
      });
    },

    cancelEdit() {
      this.dialogEditar = false;
      this.$emit("eventname", '');
    },

    desativar() {
      this.dialogAPagar = false;
      this.$emit("", this.resource.id);
    },
  },
};
</script>

<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(102, 219, 127) 0%,
    rgb(4, 99, 16) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
